import $ from 'jquery'

export default class valueChart {
  constructor (element) {
    this.$element = $(element)
    this.$charts = this.$element.find('.chart')

    this.ChartJSLib = null
    import(/* webpackChunkName: "chartjs" */ 'chart.js').then((module) => {
      this.ChartJSLib = module.Chart
      this.ChartJSLib.register(...module.registerables)

      this.$charts.each(function (index, item) {
        this.initializeChart($(item))
      }.bind(this))
    }).catch(e => {
      console.error(e)
    })
  }

  initializeChart ($chart) {
    const $ctx = $chart.find('canvas')
    const chartData = $chart.data('chartData')
    const chartType = $chart.data('chartType')
    const options = $chart.data('chartOptions')

    const chartSetting = {
      type: chartType,
      data: {
        labels: [],
        datasets: []
      },
      options: options
    }

    const myChart = new this.ChartJSLib($ctx.get(0), chartSetting)

    myChart.data.labels = chartData.labels
    myChart.data.datasets = chartData.datasets // or you can iterate for multiple datasets
    myChart.update() // finally update our chart
  }
}
